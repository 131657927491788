<template>
  <div class="pay-result-page">
       <p class="title" v-if="phoneType != -1">支付</p>
    <div class="pay-result-img"></div>
    <div class="pay-result-text">
      您的支付申请已提交，预计30分钟内有还款结果，请前往账单中查看
    </div>
    <div class="pay-result-btn" @click="goSuccessful">确认</div>
  </div>
</template>

<script>
import { onMounted, toRefs, reactive } from 'vue'
import { Toast } from 'vant'
import APP from '@/utils/App'
import { routerTag } from '../../utils/index'
export default {
  name: 'Index',
  setup () {
    const state = reactive({ phoneType: null })
    // 还款完成
    const goSuccessful = () => {
      // 跳转到账单页
      // window.TDAPP.onEvent("支付完成页-点击完成-进入");
      APP.BACK(-4)
    }
    onMounted(() => {
      let phoneType
      phoneType = navigator.userAgent.indexOf('Android')
      console.log(phoneType, '机型')
      state.phoneType = phoneType
      if (phoneType == -1) {
        APP.SET_TITLE('支付')
        APP.HIDE_BACK(true)
      } else {
        APP.SET_HEADER(false)
        // document.title("支付");
      }
    })
    return {
      ...toRefs(state),
      goSuccessful
    }
  }
}
</script>
<style scoped lang='less' src='./index.less'></style>
